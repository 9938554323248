<template>
  <div style="padding: 20px">
    <div class="query-list" style="padding-bottom: 20px">
      <el-row :gutter="20" type="flex" align="bottom" justify="space-between">
        <el-col :span="18" :offset="0">
          <el-row :gutter="20">
            <el-col :span="6" :offset="0">
              <el-input v-model="nft_id" placeholder="请输入NFT合集编号" size="normal" />
            </el-col>
            <el-col :span="6" :offset="0">
              <el-input
                v-model="badge_code"
                placeholder="请输入badge code"
                size="normal"
              ></el-input>
            </el-col>
            <el-col :span="6" :offset="0">
              <el-input v-model="user_ucid" placeholder="请输入持有人ucid" size="normal"></el-input>
            </el-col>
            <el-col :span="6" :offset="0">
              <el-select v-model="status" style="width: 100%">
                <el-option label="全部状态" value="all"> </el-option>
                <el-option label="在售" :value="1"> </el-option>
                <el-option label="未售" :value="2"> </el-option>
                <el-option label="上架审核中" :value="3"> </el-option>
                <el-option label="已销毁" :value="4"> </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6" :offset="0" style="text-align: right">
          <el-button type="primary" size="default" @click="handleClickSearch">
            查询
          </el-button>
          <el-button type="info" size="default" @click="handleClickReset">
            重置
          </el-button>
        </el-col>
      </el-row>
    </div>

    <el-table :data="list" border stripe row-key="id">
      <el-table-column label="NFT名称" prop="name"> </el-table-column>
      <el-table-column label="预览图" prop="pics" width="320px">
        <template slot-scope="scope">
          <el-image
            v-for="(pic, idx) in scope.row.pics"
            :key="idx"
            :src="pic"
            fit="fill"
            :lazy="true"
            :preview-src-list="scope.row.pics"
            style="max-width: 140px; max-height: 140px; margin-right: 8px"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="分类" prop="category_name"> </el-table-column>
      <el-table-column label="badge_code" prop="id">
        <template slot-scope="scope">
          <CopyTxid :txid="scope.row.id" />
        </template>
      </el-table-column>
      <el-table-column label="持有者信息" prop="owner_info"></el-table-column>
      <el-table-column label="在售状态" prop="on_shelf_info" :width="100"></el-table-column>
      <el-table-column label="在售状态" prop="is_frozen" :width="100">
        <template slot-scope="scope">
          <el-tag v-if="!!scope.row.is_frozen" size="small" type="danger">
            已锁定
          </el-tag>
          <el-tag v-else size="small" type="success">
            正常
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="info"
            @click="handleUnLock(scope.row)"
            style="margin: 8px"
            v-if="scope.row.is_frozen"
          >
            解锁
          </el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleLock(scope.row)"
            style="margin: 8px"
            v-else
          >
            锁定
          </el-button>

          <el-button
            size="mini"
            type="primary"
            @click="handleQueryTransfer(scope.row)"
            style="margin: 8px"
          >
            查看转让记录
          </el-button>
          <el-button
            size="mini"
            type="primary"
            @click="handleQueryTrade(scope.row)"
            style="margin: 8px"
          >
            查看交易记录
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getNFTItems, lockNFTItem, unLockNFTItem } from "@/api/nft.js";
import CopyTxid from "@/components/CopyTxid.vue";

export default {
  components: {
    CopyTxid,
  },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      // 查询参数
      // tx_id: "",
      status: "all",
      nft_id: "",
      badge_code: "",
      user_ucid: "",
    };
  },
  mounted() {
    const search = new URLSearchParams(window.location.search);
    const id = search.get("id");
    if (id) {
      this.nft_id = id;
    }
    // this.fetchNFTList();
  },
  methods: {
    getSearchParams() {
      const params = {
        page: this.page,
        page_size: this.pageSize,
      };

      this.nft_id && (params.nft_id = this.nft_id);
      this.user_ucid && (params.user_ucid = this.user_ucid);
      this.badge_code && (params.badge_code = this.badge_code);
      this.status !== "all" && (params.status = +this.status);

      return params;
    },
    async fetchNFTList() {
      // if (!this.nft_id) {
      //   this.$message.info("请选输入nft")
      //   return;
      // }

      try {
        const params = this.getSearchParams();
        const { data } = await getNFTItems(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },

    async handleLock(row) {
      await lockNFTItem({ id: row.id });
      this.$message.success("锁定成功");
      this.fetchNFTList();
    },
    async handleUnLock(row) {
      await unLockNFTItem({ id: row.id });
      this.$message.success("解锁成功");
      this.fetchNFTList();
    },

    handleQueryTrade(row) {
      this.$router.push("/nft_management/trade?badge_code=" + row.id);
    },
    handleQueryTransfer(row) {
      this.$router.push("/nft_management/transfer?badge_code=" + row.id);
    },

    handleClickSearch() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchNFTList();
    },

    handleClickReset() {
      this.resetQuery();
      this.fetchNFTList();
    },
    resetQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.nft_id = "";
      this.status = "all";
    },

    handleCurrentChange(page) {
      this.page = page;
      this.fetchNFTList();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchNFTList();
    },
    handleStatusChange() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchNFTList();
    },
  },
};
</script>

<style lang="less" scoped></style>
